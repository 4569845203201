.front_services {
	text-align: center;
	background-color: theme(secondary, base);
	@include media-breakpoint-up(lg) {
		padding-top: 0;
	}
}

	.services_item {
		@include make-col(6);
		padding: 10px;
		@include media-breakpoint-up(lg) {
			@include make-col(3);
		}
	}

.service-item {
	@include transition(all 300ms);
	display: block;
	padding: 15px;
	line-height: 1em;
	text-decoration: none;
	color: $white !important;
	strong {
		@extend %h4;
		display: block;
		margin-top: 10px;
	}
	.service-icon { @include transition(all 300ms); }
	&:hover {
		@include transition(all 300ms);
		text-shadow: 0 1px 1px rgba(black,0.3);
		.service-icon {
			@include transition(all 300ms);
			fill: $white;
		}
	}
	&.item-green:hover { background-color: $green; }
	&.item-blue:hover { background-color: $blue; }
	&.item-orange:hover { background-color: $orange; }
	&.item-purple:hover { background-color: $purple; }
}