// =====================================================
// VARIABLES - TOC
// -----------------------------------------------------
//
// 1. Grid - Bootstrap Grid Maps
//
// 2. Layout - zindex, position, padding, margin, radius, etc.
//
// 3. Typography - Font Styles
//
// 4. Colors - Universal Colors
//
// =====================================================




// =====================================================
// 1. GRID
// =====================================================
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

$container-max-widths: (
  	sm: 540px,
  	md: 720px,
  	lg: 960px,
  	xl: 1140px
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;

// =====================================================
// 2. LAYOUT
// =====================================================

$global_mb: 15px;



// =====================================================
// 4. COLORS
// =====================================================
$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$blue:    	#0c84ea !default;
$indigo:  	#6610f2 !default;
$purple:  	#cc00ff !default;
$pink:    	#e83e8c !default;
$red:     	#dc3545 !default;
$orange:  	#ff8400 !default;
$yellow:  	#ffc107 !default;
$green:   	#42aa14 !default;
$teal:    	#20c997 !default;
$cyan:    	#17a2b8 !default;
$maroon: 	#8b1b2c !default;
$gold: 		#786a4b !default;

$color-primary: 		#c10101; // YELLOW
$color-secondary: 		#333333; // DARK BLUE
$color-highlight: 		#222; // CHARCOAL
$color-accent: 			#036cb8; // LIGHT BLUE
$color-review: 			#f90;
$color-success: 		#28a745;
$color-danger: 			$red;
$color-info: 			#0182C8; //$indigo;
$color-warning: 		$yellow;

$color-facebook:		#4267b2;
$color-google:			#E94335;

// THEME COLOR DECLARATIONS
// -------------------------------------------

// THEME MAP - use function: theme(key, variant)
// ex: h1 { color: theme(primary, dark); }
// OR: div { background-color: theme(success, opac); }

// COLOR MODIFIES
$color-lighten: 15% !default;
$color-darken: 15% !default;
$color-opac: 0.2 !default;
$color-sat: 15% !default;
$color-desat: 15% !default;

$theme: (
	primary: (
		base: $color-primary,
		light: lighten($color-primary, $color-lighten),
		dark: darken($color-primary, $color-darken),
		opac: transparentize($color-primary, $color-opac),
		sat: saturate($color-primary, $color-sat),
		desat: desaturate($color-primary, $color-desat)
	),
	secondary: (
		base: $color-secondary,
		light: lighten($color-secondary, $color-lighten),
		dark: darken($color-secondary, $color-darken),
		opac: transparentize($color-secondary, $color-opac),
		sat: saturate($color-secondary, $color-sat),
		desat: desaturate($color-secondary, $color-desat)
	),
	highlight: (
		base: $color-highlight,
		light: lighten($color-highlight, $color-lighten),
		dark: darken($color-highlight, $color-darken),
		opac: transparentize($color-highlight, $color-opac),
		sat: saturate($color-highlight, $color-sat),
		desat: desaturate($color-highlight, $color-desat)
	),
	accent: (
		base: $color-accent,
		light: lighten($color-accent, $color-lighten),
		dark: darken($color-accent, $color-darken),
		opac: transparentize($color-accent, $color-opac),
		sat: saturate($color-accent, $color-sat),
		desat: desaturate($color-accent, $color-desat)
	),
	review: (
		base: $color-review,
		light: lighten($color-review, $color-lighten),
		dark: darken($color-review, $color-darken),
		opac: transparentize($color-review, $color-opac),
		sat: saturate($color-review, $color-sat),
		desat: desaturate($color-review, $color-desat)
	),
	success: (
		base: $color-success,
		light: lighten($color-success, $color-lighten),
		dark: darken($color-success, $color-darken),
		opac: transparentize($color-success, $color-opac),
		sat: saturate($color-success, $color-sat),
		desat: desaturate($color-success, $color-desat)
	),
	danger: (
		base: $color-danger,
		light: lighten($color-danger, $color-lighten),
		dark: darken($color-danger, $color-darken),
		opac: transparentize($color-danger, $color-opac),
		sat: saturate($color-danger, $color-sat),
		desat: desaturate($color-danger, $color-desat)
	),
	warning: (
		base: $color-warning,
		light: lighten($color-warning, $color-lighten),
		dark: darken($color-warning, $color-darken),
		opac: transparentize($color-warning, $color-opac),
		sat: saturate($color-warning, $color-sat),
		desat: desaturate($color-warning, $color-desat)
	),
	info: (
		base: $color-info,
		light: lighten($color-info, $color-lighten),
		dark: darken($color-info, $color-darken),
		opac: transparentize($color-info, $color-opac),
		sat: saturate($color-info, $color-sat),
		desat: desaturate($color-info, $color-desat)
	)
) !default;

// These variable's values should be equal to
// something listed above - a THEME COLOR VARIABLE.

$body-bg:				#f5f5f5;
$color-text:			$gray-800;

$color-link:			$color-accent;
$color-hover:			lighten($color-link, 10%); // theme(accent, light)

$color-phone:			$color-secondary;

// ===========================================
// 4. TYPOGRAPHY
// ===========================================

$font-family-sans-serif:  			'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-sans-serif_alt:		'Open Sans Condensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-serif:       			'Times New Roman', Georgia, Times, serif;
$font-family-monospace:   			Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-body:					$font-family-sans-serif;
$font-family-headings:				$font-family-sans-serif;

$font-size-base: 					17;
$base-line-height: 					(28 / $font-size-base) * 1em;

$body-weight-normal:				400;
$body-weight-bold:					600;
$body-weight-bolder:				800;

$heading-weight-normal:				400;
$heading-weight-bold:				700;
$heading-weight-bolder:				700;