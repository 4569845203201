.slider_reviews {

	.review { padding: 0 36px; }

    .slick-prev,
    .slick-next {
        z-index: 999999;
        height: 100%;
        width: 23px;
        background-color: transparent;
        background-image: url("/_/images/icons/slide-np-text.png") !important;
        background-size: 46px 23px;
        opacity: 0.2;
        &:hover {
            opacity: 0.65;
        }
    }	

    .slick-prev {
        left: 0;
        background-position: 0 center;
    }

    .slick-next {
        right: 0;
        background-position: -23px center;
    }

}