.block_banner {
	padding-top: 15px;
	text-align: center;

	@include media-breakpoint-up(md) {
		padding: 0 0 30px;
		.row { align-items: center; }
		.banner_button { order: 1; }
		.banner_branding { margin-bottom: 0; padding: 0; text-align: left; }
		.banner_contact { padding: 0; }
			.banner-call { margin: 0 0 0 auto; max-width: 270px; }
		.banner_best { margin-bottom: 0; padding: 0; }
		.menu_main { order: 5; }
	}
		
		@include media-breakpoint-only(md) {
			.banner_branding { @include make-col(4); order: 2; }
			.branding-logo { width: 180px; }
			.banner_best {
				@include make-col(3);
				order: 3;
				text-align: left;
			}
			.banner_contact { @include make-col(5); order: 4;  }
				.banner-call {  }
		}

	@include media-breakpoint-up(lg) {
		.banner_branding { @include make-col(4); order: 3; text-align: center; }
		.banner_contact { @include make-col(4); order: 4; padding-right: 15px; }
		.banner_best { @include make-col(4); order: 2; padding-left: 5px; text-align: left; }
	}
}

	.banner_button {
		text-align: left;
		padding: 0;
	}

	.banner_branding {
		margin-bottom: 15px;
	}

	.banner_contact {
		//margin-bottom: 15px;
	}

		.banner-call {
			max-width: 255px;
			margin: 0 auto 15px;
			padding: 5px 0;
			text-align: right;
			background: url("/_/images/associations/iicrc.png") top left no-repeat;
			background-size: contain;
		}	

	.banner_best {
		margin-bottom: 15px;
	}

	.menu_top {
		@include media-breakpoint-up(md) {
			max-height: 42px;
			overflow: hidden;
			.btn-social {
				border-radius: 0;
				border: none;
				float: right;
				padding: 12px 15px;
			}
		}
	}