body {

	min-width: 360px;
	background-color: $body-bg;

	@include media-breakpoint-up(md) {
		background: $body-bg url("/_/images/layout/body-bg-lg.jpg") top center no-repeat;
		background-size: cover;
		background-attachment: fixed;
		//background: $body-bg url("/_/images/layout/body-bg.png");
	}

	&:not(.page_front) {
			
		@include media-breakpoint-up(sm) {

			height: 100%;
			padding-bottom: 127px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}


}

#directions,
#hours {
	outline: none !important;
	box-shadow: none !important;
}