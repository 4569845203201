.branding-title {
	margin: 0;
	font-weight: normal;
	line-height: 1em;
}

.logo {
	display: inline-block;
}

	.branding-logo {
		width: 190px;
		@include transition(transform 200ms);
		@include transform(scale(0.95));
		&:hover {
			@include transform(scale(1));
		}
	}

	.site-info-logo {}