.front_about {

	padding: 45px 15px;

	@include media-breakpoint-up(lg) {
		padding: 45px 0;
		.about_bg {
			padding: 0 15px;
			background-image: linear-gradient(to right, darken($color-primary, 3%) 50%, transparent 50%);
		}
		.row { align-items: center; }
		.about_text {
			@include make-col(7);
			padding: 30px 30px 30px 0;
			background-color: darken($color-primary, 3%) ;
			color: $white;
		}
		.about_media {
			@include make-col(5);
			padding-left: 30px;
		}
	}

}