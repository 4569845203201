.block {
	padding: 30px 15px;
}

.block_front {}

	.block_title {
		padding: 0 0 15px;
	}

		.block-title {
			margin: 0;
		}

	.block_media {}

	.block_text {}

	.block_more {}