.front_jumbo {

	@include media-breakpoint-up(lg) {
		background-image: linear-gradient(to top, theme(secondary, base) 50%, transparent 40%);
		.container {
			padding: 0;
			border: 2px solid $white;
			background-color: $white;
		}
		.row { margin: 0; }
		.jumbo_marketing { @include make-col(8); }
		.jumbo_conversion { @include make-col(4); }
	}

}

	.jumbo_marketing {
		padding: 0;
		@include media-breakpoint-up(lg) {
			padding: 0 10px 0 0;
		}
	}

	.jumbo_conversion {
		margin-top: 20px;
		padding: 10px 20px 20px;
		background-color: $white;
		@include media-breakpoint-up(lg) {
			margin-top: 0;
			padding: 0 20px 0 10px;
		}
	}
