.menu_top {
	margin-bottom: 30px;
	text-align: left;
	line-height: 0;
	background-color: theme(secondary, base);
}

	.menu-top {
		display: inline-block;
		@include list-inline;
		text-align: left;
	}

	.menu-top > .menu-item {
		&:not(:last-child) { border-right: 1px solid rgba(white, 0.2); }
		&.active > .menu-link {
			font-weight: $heading-weight-bolder;
			background-color: #F0F0F0;
			color: $color-text;
		}
	}

	.menu-top .menu-link {
		padding: 12px 15px 13px;
		//@include font-size(15);
		font-weight: $body-weight-bold;
		line-height: 1em;
		color: $white;
		&:hover {
			background-color: theme(primary, base);
		}
	}
