.front_reviews {
	border-top: 2px solid $color-review;
	border-bottom: 2px solid $color-review;
	background-color: $white;
	text-align: center;
	.gmb-button-review {
		margin: 0 auto 15px;
	}

	@include media-breakpoint-up(md) {
		.gmb-buttton-review { margin-bottom: 0; }
		.review_media {
			@include make-col(6);
		}
	}

	@include media-breakpoint-down(lg) {
		.reviews_text {
			margin-bottom: 20px;
		}
	}

	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.reviews_text {
			@include make-col(9);
		}
	}

	@include media-breakpoint-up(xl) {
		.row { align-items: center; }
		.reviews_text {
			-webkit-box-flex: 0;
    		flex: 0 0 60%;
    		max-width: 60%;
			order: 2;
			padding: 0;
		}
		.review_media {
			-webkit-box-flex: 0;
			flex: 0 0 20%;
			max-width: 20%;
			padding: 0;
			> a {
				@include transform(scale(0.85));
				margin: 0 -18px 0 0;
				line-height: 1em;
				&.gmb-button-review { margin: 0 0 0 -18px; }
			}
		}
		.media_google { order: 1; text-align: left; }
		.media_bbb { order: 3; text-align: right; }
	}

}

	.review_media {
		a:not(.gmb-button-review) { display: block; }
	}