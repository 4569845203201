.block_announce {
	text-align: center;
	background-color: theme(primary, base);
	color: $white;

	@include media-breakpoint-up(md) {
		padding: 15px 0;
		line-height: 1em;
		.wrap { @include make-col(4); }
	}

}