.slider_jumbo {

    margin: 0 auto;
    line-height: 0px;

    &:hover {
        .slick-prev,
        .slick-next { opacity: 1; }
    }

    .slick-prev,
    .slick-next {
        z-index: 999999;
        height: 100%;
        width: 23px;
        background-color: transparent;
        background-image: url("/_/images/icons/slide-np-white.png") !important;
        background-size: 46px 23px;
        opacity: 0;
        &:hover {
            opacity: 1;
        }
    }

    .slick-prev {
        left: 5px;
        background-position: 0 center;
    }
    .slick-next {
        right: 5px;
        background-position: -23px center;
    }

    .slide-placeholder {
        width: 100%;
    }


    .slide {
        position: relative;
    }

    .slide_caption {
        padding: 10px;
        background-color: theme(primary, base);
        color: $white;
    }

        .caption-title {
            margin: 0;
            @include font-size(18);
            font-weight: $heading-weight-bold;
        }

    @include media-breakpoint-up(md) {
        .slide_caption { padding: 15px; }
        .caption-title { @include font-size(22); }
    }
        
        @include media-breakpoint-only(md) {
            .slide-placeholder { max-height: 420px; }
        }

    @include media-breakpoint-up(xl) {
        .slide-placeholder { max-height: 520px; }
    }

}