.review {

	p {
		margin-bottom: 0;
		line-height: 1.425em;
	}

	cite {
		display: block;
		margin-top: 15px
	}

	.icon-review {
		display: block;
		height: 25px;
		width: 125px;
		margin: 0 auto;
		fill: $color-review;
	}

}