.menu_main {
	margin-top: 15px;
}

	.menu-main {
		@include make-flex;
	}

	.menu-main > .menu-item {
		@include make-flex;
		@include make-col(2);
		padding: 0 4px;
		&.active > .menu-link {
			border: none !important;
			background-color: transparent !important;
			//background: $body-bg url("/_/images/layout/body-bg.png");
			color: theme(primary, base) !important;
			b { font-weight: $body-weight-bolder; }
		}
	}

	.menu-main > .menu-item > .menu-link {
		@include make-flex;
		align-items: center;
		width: 100%;
		padding: 12px 20px;
		border-radius: 8px;
		border: 1px solid #ccc;
		background-color: $white;
		color: $color-text;
		&:hover {
			border-color: theme(primary, base);
			background-color: theme(primary, base);
			color: $white;
		}
		b { width: 100%; }
		@include media-breakpoint-up(lg) {
			padding: 20px 10px;
			@include font-size(17);
		}
	}