.form_quote {

	.form_group.form_radio label {
		margin-bottom: 10px;
		padding: 5px;
		.radio { margin-top: -3px; }
	}
	.checkbox_group .form_group {
		margin-bottom: 10px;
		label { padding: 5px; }
		.checkbox { margin-top: -2px; }
	}

	@include media-breakpoint-up(md) {
		
		.form_phone,
		.form_email {
			float: left;
			width: 49.5%;
		}

		.form_email { float: right; }

		.checkbox_group {
			@include make-flex;
			.form_checkbox {
				width: percentage(1/2);
			}
		}

		.form_radio {
			@include make-flex;
			label { margin-right: 15px; }
		}

	}


}