.front_choose {
	padding: 45px 15px 30px;
	//background-color: white;

	ul { margin: 0; }
	ul+ul { margin-top: 5px; }

	@include media-breakpoint-up(md) {
		text-align: center;
		
		ul+ul { margin-top: 0; }
		ul { padding: 0 10px; }
		.list-wrap { justify-content: center; }
	}

	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.wrap { @include make-col(10); }
	}

}

	.choose_media {

		margin-top: 20px;
		padding-top: 20px;
		text-align: center;
		border-top: 1px solid #ccc;

		img {
			display: inline-block;
			margin: 0 15px;
			height: 60px;
		}

	}